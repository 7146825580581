<template>
  <div class="documents-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Widget>
      <DataTable
        class="p-datatable-sm"
        :value="filteredOffers"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        :scrollable="true"
        scrollHeight="650px"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('offers') }}</h1>
            </div>
            <div class="table-header-right">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span>

              <div class="button-right">
                <button class="btn btn-inverse mb-2 ml-2" @click.prevent="onRemoveFilters()">
                  <i class="fa fa-remove mr-3"></i>
                  {{ $t('remove_filters') }}
                </button>
                <router-link class="btn btn-success mb-2 right" :to="{ name: 'OfferCreatePage' }">
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('offerCreate') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>
        <Column field="number" header="#" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'OfferEditPage',
                params: { offerNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="pdf" :header="$t('pdf')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <a v-if="slotProps.data.pdf" @click.prevent="openPdf(slotProps.data.number)">
              <img class="icon" src="../../assets/pdf.svg" alt="pdf" />
            </a>
          </template>
        </Column>
        <Column
          field="customerName"
          :header="$t('customerName')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customerName']" class="p-column-filter" />
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: 'DocumentsPage',
  components: {
    Breadcrumbs,
    DataTable,
    Widget,
    InputText,
    Column,
  },
  props: ['msg'],
  data() {
    return {
      loading: true,
      filters: {},
      filteredOffers: [],
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getOffers']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('documents') }];
    },
  },
  methods: {
    ...mapActions(['fetchOffers', 'fetchOfferPdfUrl']),
    onRemovePowerCompany(value) {
      this.filteredOffers = this.getOffers;
    },
    onRemoveFilters() {
      this.sortOrder = null;
      this.sortField = null;
      this.filters = {};
      this.filteredOffers = this.getOffers;
    },
    async openPdf(offerNumber) {
      const url = await this.fetchOfferPdfUrl(offerNumber);
      window.open(url);
    },
  },
  async mounted() {
    await this.fetchOffers();
    this.filteredOffers = this.getOffers;
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 120px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(2),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(2) {
  width: 120px;
  text-align: center;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
.expansion {
  text-align: left;
  margin-left: 3.5em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
.btn-narrow {
  padding: 0.375rem 1rem;
  max-height: 33.5px;
}
.icon {
  width: 32px;
}
</style>
